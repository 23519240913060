import * as React from 'react';
import { navigate } from 'gatsby';

interface PageProps {
  location: Location;
}
const Page: React.FC<PageProps> = ({ location }) => {
  React.useEffect(() => {
    navigate(`/zh-Hant/coupon${location.search}`, { replace: true });
  }, []);
  return null;
};

export default Page;
